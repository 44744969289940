import React from 'react';
import includes from 'lodash.includes';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import * as H from 'history';
import { useTranslation } from 'react-i18next';
import { IApplicationState } from '@store';
import { UserConnect } from '@containers/shared/Connect';
import { FillButton } from '@components/shared/Button';
import { IconType, TradeInstrument } from '@enums';
import { insertWhen } from '@common/array';
import SupplyActionError from '@enums/SupplyActionError';
import { ISupplyLine } from '@models';
import { addToSearch } from '@common/url';
import { supplyMarkingActions } from '@store/supply-marking';
import { useBreakpoint } from '@containers/withBreakpoint';

const ALLOWED_CLOCK_MARKING_LOCATIONS = ['AM', 'NW', 'RB', 'ED'];

type IMarkButtonsProps = {
  selection: ISupplyLine[];
};

function MarkButtons({ selection }: IMarkButtonsProps) {
  const dispatch = useDispatch();
  const koa = useSelector((state: IApplicationState) => state.koa);
  const user = useSelector(({ user: { user: currentUser } }: IApplicationState) => currentUser);
  const { t } = useTranslation('marketPlace');
  const { isBreakpointSmall } = useBreakpoint();
  const filteredSelection = selection
    .filter(s => s.tradeInstrument === TradeInstrument.Clock)
    .filter(s => s.deliveries.length === 1 && includes(ALLOWED_CLOCK_MARKING_LOCATIONS, s.deliveries[0].location.toUpperCase()));

  const getMarkErrors = () => {
    const { selection: selectedKoaAccounts } = koa;

    const containsClockSupply = filteredSelection.length > 0;
    const hasSelectedAccounts = selectedKoaAccounts.length > 0;

    return [...insertWhen(!containsClockSupply, SupplyActionError.NoClockSupply), ...insertWhen(!hasSelectedAccounts, SupplyActionError.NoAccounts)];
  };

  const errors = getMarkErrors();
  const hasErrors = errors.length > 0;

  const getQueryString = (location: H.Location) => {
    return addToSearch(location.search, { errors });
  };

  const onMarkClicked = (authorizedToMark: boolean) => {
    if (authorizedToMark) {
      dispatch(supplyMarkingActions.markCurrentSelection());
    }
  };

  const getTo = (authorizedToMark: boolean, routeProps: RouteProps, path?: string) => {
    if (!authorizedToMark) return { pathname: '/marketplace/mark-unauthorized', search: true };
    if (hasErrors) return { pathname: '/marketplace/incorrect-action', search: getQueryString(routeProps.location) };
    if (path) return { pathname: path, search: true };

    return null;
  };

  return (
    <UserConnect>
      {(_userState, { authorizedToMark }) =>
        user.authorization.mark.read && (
          <Route>
            {(routeProps: RouteProps) => (
              <>
                <FillButton
                  label={t('marketPlace:actions.mark') + (isBreakpointSmall ? ` (${filteredSelection.length})` : '')}
                  to={getTo(authorizedToMark, routeProps)}
                  icon={IconType.Star}
                  onClick={!hasErrors ? () => onMarkClicked(authorizedToMark) : null}
                  color="white"
                />
                {!isBreakpointSmall && (
                  <FillButton
                    label={t('marketPlace:actions.markDialog')}
                    to={getTo(authorizedToMark, routeProps, '/marketplace/mark')}
                    icon={IconType.Star}
                    color="white"
                  />
                )}
              </>
            )}
          </Route>
        )
      }
    </UserConnect>
  );
}

export default React.memo(MarkButtons);
