import React from 'react';
import { useTranslation } from 'react-i18next';
import { FillButton } from '@components/shared/Button';
import { UserConnect } from '@containers/shared/Connect';

type ISupplyLineBuyButtonProps = {
  to: string;
  className?: string;
  testHook?: string;
};

export default function SupplyLineBuyButton({ to, className, testHook = 'buy-btn' }: ISupplyLineBuyButtonProps) {
  const { t } = useTranslation('general');

  return (
    <UserConnect>
      {(_userState, { authorizedToBuy }) => (
        <FillButton
          label={t('buy')}
          to={{ pathname: authorizedToBuy ? to : '/marketplace/cps-unauthorized', search: true }}
          important
          className={className}
          testHook={testHook}
        />
      )}
    </UserConnect>
  );
}
